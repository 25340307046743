import React, { useState } from "react";
import { Link } from "gatsby"
import { Container, Row, Col, Form } from "react-bootstrap"
import ScrollAnimation from 'react-animate-on-scroll';
import "./Update.scss";
import "../NewsInfo/NewsInfo.scss";
import Bookcard from "../BookCard/BookCard";
import HTMLReactParser from "html-react-parser";
// Header component

const Update = (props) => {
    const bookcardData = [
        {
            bookcardTitle: 'Sell or Let your property with us',
            bookcardContent: 'Lorem ipsum dolor sit amet, consectetur adipiscing elitam accumsan.',
            bookcardBtn: 'book an appointment',
            bookcardLink: '#'
        }
    ]
    return (
        <React.Fragment>
            <section className="updated-info news-info-section">
                <Container>
                    <Row>
                        <Col md={12}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div className="content-box">
                                    <div className="content-box-subdiv">
                                        {props?.data?.Content_with_Image.map((each, i) =>
                                            <React.Fragment key={i}>
                                                {each.Show_Form_On_top &&
                                                    <div className='book-card update d-none d-md-block order-xxl-3'>
                                                        <ScrollAnimation animateIn="fadeInUp">
                                                            <div className="book-card-appointment">
                                                                <div className="appointment-content">
                                                                    <h2>Stay updated with all of our latest news and events</h2>
                                                                </div>
                                                                <Form className="d-flex">
                                                                    <Form.Group className="mb-3 " >
                                                                        <Form.Control type="text" placeholder="Name" />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3 form-wrap" >
                                                                        <Form.Control type="email" placeholder="Email Address" />
                                                                    </Form.Group>
                                                                    <Link href="#" className="btn">subscribe</Link>
                                                                </Form>

                                                                <span className="form-info">By clicking Subscribe, you agree to our <Link href="#" >Terms & Conditions</Link>  and <Link href="#">Privacy Policy. </Link></span>
                                                            </div>
                                                        </ScrollAnimation>
                                                    </div>
                                                }
                                                {each.Content &&

                                                    <Col md={12}>
                                                        <div className="news-details content-wrap">
                                                            {HTMLReactParser(each.Content)}
                                                        </div>
                                                    </Col>
                                                }
                                                <Col md={12} className="news-img-wrapper">
                                                    {each.Images.map((img, k) =>
                                                        <div className="news-info-img " >
                                                            <img src={img?.url} alt={img?.alternativeText ? img?.alternativeText : props?.data?.Title + " - tlc Estate Agents"} />
                                                        </div>
                                                    )}
                                                </Col>
                                                {each.Show_Book_Cta_On_Bottom &&
                                                    <Col md={12} className="book-news-card">
                                                        <Bookcard
                                                            bookcardTitle={bookcardData[0].bookcardTitle}
                                                            bookcardContent={bookcardData[0].bookcardContent}
                                                            bookcardBtn={bookcardData[0].bookcardBtn}
                                                            bookcardLink={bookcardData[0].bookcardLink}
                                                        />
                                                    </Col>
                                                }
                                            </React.Fragment>
                                        )}
                                    </div>
                                </div>
                            </ScrollAnimation>


                        </Col>
                    </Row>
                </Container>
            </section>

        </React.Fragment>
    )
}
export default Update
