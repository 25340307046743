import { graphql } from "gatsby";
import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import Seo from "../components/seo";
import "../components/NewsDetails/NewsDetails.scss";
import NewsBanner from "../components/NewsBanner/NewsBanner";
import Update from "../components/NewsUpdate/Update";
import LocalNews from "../components/LocalNews/LocalNews";
import { PageLinks } from '../components/common/constant';

const NewsVideosTemplate = (props) => {
    const GQLPage = props.data.glstrapi?.newsAndVideo;
    // const module = props.data.glstrapi?.globalModule;
    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Title}
                description={`Read about ${GQLPage.Title} here and subscribe to our newsletter to stay up-to-date about everything going on at tlc.`}
                location={props.location}
            />
            <Header menuLinks={[]} />
            <div className="newsdetails-page">
                <NewsBanner
                    data={GQLPage}
                    breadcrumbs={{ title: 'News', url: "/about/latest-property-news-and-videos/" }}

                />
                <Update
                    data={GQLPage}
                />
                <LocalNews
                    title="other articles that may interst you..."
                />
            </div>
            <Footer
                popularSearch="Popular_About"  showBreadcrumb = {true}
            />
        </React.Fragment>
    )
}

export default NewsVideosTemplate;


export const pageQuery = graphql`
    query newsAndVideoQuery($id: ID!) {
        glstrapi {
            newsAndVideo(id: $id, publicationState: LIVE) {
                _id
                Category {
                    Title
                    _id
                    Sort
                }
                Url
                Sort
                Tile_Image {
                    url
                    alternativeText
                }
                Publish
                Content_with_Image {
                    _id
                    Title
                    Content
                    Images {
                        alternativeText
                        url
                    }
                    Show_Book_Cta_On_Bottom
                    Show_Form_On_top
                }
                Banner_Image {
                    alternativeText
                    url
                }
                Date
                Sub_Category
                Title
                Video_Url
                imagetransforms
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                }
            }
        }
    }
`

